import axios from 'axios';
import CryptoJS from "crypto-js";

export const Axios = axios.create({
    headers: {
        "Pyxis-Auth-Token": localStorage.getItem("accessToken")
    },
});
Axios.interceptors.response.use(async function (response) {
    if (!response.data.success && response.data.code === "error.authentication.needLogin") {
        if (getLastLogin()) {
            // alert("토큰이 만료되어 재로그인합니다");
            await login(getLastLogin().id, getLastLogin().password);
            window.location.reload();
        }
    }
    return response;
});

export const saveAccessToken = (accessToken) => {
    localStorage.setItem("accessToken", accessToken);
    Axios.defaults.headers = {
        "Pyxis-Auth-Token": accessToken
    };
};

const getLastLogin = () => JSON.parse(localStorage.getItem("lastLoginedWith"));

export const login = async (id, password) => {
    const payload = {
        loginId: id,
        password: encryptPassword(password),
        isFamilyLogin: false,
        isMobile: false,
    };

    const response = await Axios.post("https://oasis.ssu.ac.kr/pyxis-api/api/login", payload);
    if (response.data.success) {
        saveAccessToken(response.data.data.accessToken);
        localStorage.setItem("lastLoginedWith", JSON.stringify({ id, password }));
    }
    return response.data;
};

const encryptPassword = (password) => {
    const key = CryptoJS.enc.Hex.parse("180e26b97b74886629807dca66824183");
    const iv = CryptoJS.enc.Hex.parse("5b6b723a696e656b3a736f6c7665645d");
    return CryptoJS.AES.encrypt(password, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
};

export const getSeats = async () => {
    const response = await Axios.get("https://oasis.ssu.ac.kr/pyxis-api/1/api/rooms/53/seats");
    return response.data;
};

export const occupySeat = async (id) => {
    const payload = {
        seatId: id,
        smufMethodCode: "PC"
    };

    const response = await Axios.post("https://oasis.ssu.ac.kr/pyxis-api/1/api/seat-charges", payload);
    return response.data;
};

export const getOwnSeat = async () => {
    const response = await Axios.get("https://oasis.ssu.ac.kr/pyxis-api/1/api/seat-charges");
    return response.data;
};

export const renewSeat = async (id) => {
    const payload = {
        seatCharge: id,
        smufMethodCode: "PC"
    };

    const response = await Axios.post("https://oasis.ssu.ac.kr/pyxis-api/1/api/seat-renewed-charges", payload);
    return response.data;
};

export const dischargeSeat = async (id) => {
    const payload = {
        seatCharge: id,
        smufMethodCode: "PC"
    };

    const response = await Axios.post("https://oasis.ssu.ac.kr/pyxis-api/1/api/seat-discharges", payload);
    return response.data;
};

export const getMembershipCard = async () => {
    const response = await Axios.get("https://oasis.ssu.ac.kr/pyxis-api/1/api/my-membership-card?type=qrcode");
    return response.data;
};
