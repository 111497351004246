import { Button, styled } from "@mui/material";

export const FailableButton = styled(Button)`
  &.failed {
    animation: vibration 0.1s infinite;
    color: ${(props) => (props.variant === "contained" ? "" : "red")};
    border-color: red;
    background-color: ${(props) =>
      props.variant === "contained" ? "red" : ""};
  }

  @keyframes vibration {
    from {
      transform: translateX(3px);
    }
    to {
      transform: rotate(-3px);
    }
  }
`;
