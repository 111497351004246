import React, { useRef, useState } from "react";
import { getMembershipCard } from "../api";
import QRCode from "qrcode.react";
import { useSnackbar } from "notistack";
import { Dialog, DialogContent, Fab } from "@mui/material";
import { QrCode } from "@mui/icons-material";

export default function MembershipQR() {
  const { enqueueSnackbar } = useSnackbar();
  const [membershipCard, setMembershipCard] = useState(null);
  const open = Boolean(membershipCard);

  const intervalRef = useRef();

  const startAutomaticRefresh = () => {
    handleQrRefresh();
    intervalRef.current = setInterval(handleQrRefresh, 10000);
  };

  const handleQrRefresh = async () => {
    const response = await getMembershipCard();
    if (response.success === true) {
      setMembershipCard(response.data.membershipCard);
      return;
    }
    enqueueSnackbar("QR 발급 실패", { variant: "error" });
    setMembershipCard(null);
  };

  const handleQrClose = () => {
    clearInterval(intervalRef.current);
    setMembershipCard(null);
  };

  return (
    <>
      <Fab
        color="primary"
        onClick={startAutomaticRefresh}
        sx={{ position: "fixed", right: 10, bottom: 10, zIndex: 999 }}
      >
        <QrCode />
      </Fab>
      <Dialog open={open} onClose={handleQrClose}>
        <DialogContent>
          {membershipCard ? (
            <QRCode value={membershipCard} size={192} />
          ) : (
            <></>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
