import React, { useEffect, useRef, useState } from "react";
import { getOwnSeat, getSeats } from "../api";
import OwnSeat from "./OwnSeat";
import Seat from "./Seat";
import { Box, Card, CardContent, Grid, LinearProgress, Typography } from "@mui/material";

const sortCriteria = [
  { id: "code", name: "좌석번호", sortFunction: (a, b) => a.code - b.code },
  { id: "codeReversed", name: "좌석번호 역순", sortFunction: (a, b) => b.code - a.code },
  { id: "remainingTime", name: "남은시간", sortFunction: (a, b) => a.remainingTime - b.remainingTime }
];

export default function Seats() {
  const [seats, setSeats] = useState([]);
  const [ownSeats, setOwnSeats] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const [sortBy, setSortBy] = useState(sortCriteria[2]);
  const [filteredCode, setFilteredCode] = useState("");
  const [removeOccupied, setRemoveOccupied] = useState(false);
  const [withMonitor, setWithMonitor] = useState(true);
  const [refreshProgress, setRefreshProgress] = useState(0);

  const refreshRef = useRef();
  const progressRef = useRef();

  const refreshSeats = async () => {
    const response = await getSeats();
    if (response.success === true) {
      setSeats(response.data ? response.data.list : []);
    }
  };

  const refreshOwnSeats = async () => {
    const response = await getOwnSeat();
    if (response.success) {
      setOwnSeats(response.data ? response.data.list : []);
      return;
    }
  };

  const filterAndSortSeats = () => {
    const filteredAndOrdered = [...seats]
      .filter(it => removeOccupied ? !it.isOccupied : true)
      .filter(it => filteredCode !== "" ? it.code.startsWith(filteredCode) : true)
      .filter(it => withMonitor ? 21 <= it.code && it.code <= 35 : true)
      .sort(sortBy.sortFunction);
    setFiltered(filteredAndOrdered);
  }

  const handleSortChange = e => {
    sortCriteria.filter(it => it.id === e.target.value)
      .forEach(it => setSortBy(it));
  }

  useEffect(() => {
    refreshSeats();
    refreshOwnSeats();
  }, []);

  useEffect(() => {
    clearInterval(refreshRef.current)
    refreshRef.current = setInterval(() => {
      refreshSeats();
      refreshOwnSeats();
    }, 20000);
    clearInterval(progressRef.current)
    progressRef.current = setInterval(() => {
      setRefreshProgress(prev => (prev + 1) % 21);
    }, 1000);
  }, []);

  useEffect(() => {
    filterAndSortSeats();
  }, [seats, sortBy, filteredCode, removeOccupied, withMonitor]);

  return (
    <>
      <Box sx={{ width: "100%", position: "fixed", top: 0, left: 0 }} >
        <LinearProgress variant="determinate" value={refreshProgress / 20 * 100} sx={{ backgroundColor: "transparent" }} />
      </Box>
      <Card sx={{ marginTop: 2, marginBottom: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            {
              ownSeats
                .map(it => <OwnSeat data={it} key={it.id} successCallback={refreshOwnSeats} />)
            }
          </Grid>
        </CardContent>
      </Card>
      <Card sx={{ marginTop: 2 }}>
        <CardContent>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6">옵션</Typography>
            <div>
              {
                sortCriteria.map(it =>
                  <label key={it.id}>
                    <input
                      type="radio"
                      value={it.id}
                      checked={sortBy.id === it.id}
                      onChange={handleSortChange}
                    />
                    {it.name}
                  </label>
                )
              }
            </div>
            <div>
              <input
                type="text"
                placeholder="좌석번호"
                value={filteredCode}
                onChange={(e) => setFilteredCode(e.target.value)}
              />
              <label>
                <input
                  type="checkbox"
                  checked={removeOccupied}
                  onChange={(e) => setRemoveOccupied(e.target.checked)}
                />
                사용중 제외
              </label>
              <label>
                <input
                  type="checkbox"
                  checked={withMonitor}
                  onChange={(e) => setWithMonitor(e.target.checked)}
                />
                🖥️
              </label>
            </div>
          </Box>
          <Grid container spacing={2}>
            {
              filtered
                .map(it => <Seat data={it} key={it.id} successCallback={refreshOwnSeats} />)
            }
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}