import React, { useState } from "react";
import { login } from "../api";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { FailableButton } from "./FailableButton";

export default function Login() {
  const { enqueueSnackbar } = useSnackbar();

  const getLastLogin = () =>
    JSON.parse(localStorage.getItem("lastLoginedWith"));
  const [loginId, setLoginId] = useState(getLastLogin()?.id);
  const [password, setPassword] = useState(getLastLogin()?.password);

  let vibrationTimer = null;
  const handleLogin = async (e) => {
    const currentTarget = e.currentTarget;
    const response = await login(loginId, password);
    if (response.success === true) {
      enqueueSnackbar("로그인 성공", { variant: "success" });
      return;
    }
    currentTarget.classList.add("failed");
    clearTimeout(vibrationTimer);
    vibrationTimer = setTimeout(() => {
      currentTarget.classList.remove("failed");
    }, 300);
  };

  return (
    <Card>
      <CardHeader title="로그인" sx={{ textAlign: "center" }} />
      <CardContent>
        <TextField
          label="학번"
          variant="standard"
          value={loginId}
          onChange={(e) => setLoginId(e.target.value)}
          fullWidth
        />
        <TextField
          type="password"
          variant="standard"
          label="비밀번호"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          fullWidth
        />
      </CardContent>
      <CardActions sx={{ justifyContent: "right" }}>
        <FailableButton variant="contained" onClick={handleLogin}>
          Login
        </FailableButton>
      </CardActions>
    </Card>
  );
}
