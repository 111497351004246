import React from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Delete, Recycling } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { dischargeSeat, renewSeat } from "../api";

export default function OwnSeat({ data, successCallback }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleRenewal = async () => {
    const response = await renewSeat(data.id);
    if (response.success) {
      enqueueSnackbar("연장 성공", { variant: "success" });
      successCallback();
      return;
    }
    enqueueSnackbar("연장 실패", { variant: "error" });
  };

  const handleDischarge = async () => {
    const response = await dischargeSeat(data.id);
    if (response.success) {
      enqueueSnackbar("반납 성공", { variant: "success" });
      successCallback();
      return;
    }
    enqueueSnackbar("반납 실패", { variant: "error" });
  };

  return (
    <Grid item xs={6} md={4}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            {21 <= data.seat.code && data.seat.code <= 35 ? <>🖥️ </> : <></>}
            {data.seat.code}번
          </Typography>
          <p>
            <b>{data.remainingTime}</b>분 남음
          </p>
          <p>
            {data.chargeTime - data.remainingTime}분/총 {data.chargeTime}분
          </p>
          <p>{data.isRenewable ? <>연장가능</> : <>연장불가</>}</p>
          <p>
            <b>{data.state.name}</b>
          </p>
          <Stack spacing={1}>
            {data.isRenewable ? (
              <Button
                variant="outlined"
                onClick={handleRenewal}
                startIcon={<Recycling />}
                fullWidth
              >
                연장
              </Button>
            ) : (
              <></>
            )}
            <Button
              color="warning"
              variant="outlined"
              onClick={handleDischarge}
              startIcon={<Delete />}
              fullWidth
            >
              반납
            </Button>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}
