import React from "react";
import { occupySeat } from "../api";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { Send } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { FailableButton } from "./FailableButton";

export default function Seat({ data, successCallback }) {
  const { enqueueSnackbar } = useSnackbar();
  
  let vibrationTimer = null;
  const handleReservation = async (e) => {
    const currentTarget = e.currentTarget;
    const response = await occupySeat(data.id);
    if (response.success) {
      enqueueSnackbar("예약 성공", { variant: "success" });
      successCallback();
      return;
    }
    currentTarget.classList.add("failed");
    clearTimeout(vibrationTimer);
    vibrationTimer = setTimeout(() => {
      currentTarget.classList.remove("failed");
    }, 300);
  };

  return (
    <Grid item xs={6} md={4}>
      <Card>
        <CardContent>
          <Typography variant="h6">
            {21 <= data.code && data.code <= 35 ? <>🖥️ </> : <></>}
            {data.code}번
          </Typography>
          <p>
            <b>{data.remainingTime}</b>분 남음
          </p>
          <p>
            {data.chargeTime - data.remainingTime}분/총 {data.chargeTime}분
          </p>
          <p>
            {data.isOccupied ? <>사용중, </> : <>비었음, </>}
            {data.isReservable ? <>예약가능</> : <>예약불가</>}
          </p>
          <FailableButton
            variant="outlined"
            onClick={handleReservation}
            fullWidth
            endIcon={<Send />}
          >
            예약
          </FailableButton>
        </CardContent>
      </Card>
    </Grid>
  );
}
