import "./App.css";
import React from "react";
import Login from "./components/Login";
import Seats from "./components/Seats";
import Container from "@mui/material/Container";
import { SnackbarProvider } from "notistack";
import MembershipQR from "./components/MembershipQR";

function App() {
  return (
    <Container maxWidth="sm" sx={{my: 2}}>
      <SnackbarProvider maxSnack={3}>
        <Login />
        <MembershipQR />
        <Seats />
      </SnackbarProvider>
    </Container>
  );
}

export default App;
